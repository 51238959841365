<template>
   <div class="top-mobile-search">
        <div class="row">
            <div class="col-md-12">   
                <form class="mobile-search">
                    <div class="input-group">
                        <input type="text" placeholder="Search for..." class="form-control">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-dark"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </form>   
            </div>
        </div>
    </div>
    <div class="video-block section-padding">
        <div class="row">
            <div class="col-md-12">
                <div class="main-title">
                    <h6>نتائج البحث : {{ $route.params.search_id }}</h6>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-3 col-xl-3" v-for="(publish, index) in searchData" :key="index">
                <div class="video-card">
                    <div class="video-card-image">
                        <a class="play-icon" @click="getFeed(publish)"><i class="fas fa-play-circle"></i></a>
                        <a @click="getFeed(publish)"><img class="img-fluid" v-lazy="publish.img" alt></a>
                        <div class="time">{{ publish.file_duration }}</div>
                    </div>
                    <div class="video-card-body">
                        <div class="video-title">
                            <a @click="getFeed(publish)">{{ publish.title_ar }}</a>
                        </div>
                        <div class="video-page text-success">
                            {{ publish.name_ar }}  <a title data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                             &nbsp;<i class="fas fa-calendar-alt"></i> {{ publish.upload_date.substring(0,10) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-0">
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
  name: 'AppSearch',
  props:{
        search_id: String
    },
    async setup(props) {
        const cookie = useCookie()
        const router = useRouter();
        const searchData = ref([]);
        const GetSearch = async () => {
            await HTTP.get(`GetSearchContent.php?search=`+ props.search_id).then((res) => {
                searchData.value = res.data.Content;
            })
        }
        onMounted(() => {
            GetSearch();
        });

        const getFeed = (publish) => {
            cookie.setCookie('cat_id', publish.cat_id);
            router.push({ name: "Contents", params: { id: publish.id } });
        };
        return {
            searchData,
            getFeed
        };
    },
}
</script>

<style>

</style>